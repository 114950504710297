<template>
	<div class="py-4 container-fluid">
		<div class="row">

			<!-- Venues -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Venues"
					name="Venues"
					:value="analytics.venues"
					:icon="{
						component: 'ni ni-building',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Members -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Members"
					name="Members"
					:value="analytics.members"
					:icon="{
						component: 'ni ni-user-run',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Events -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Events"
					name="Events"
					:value="analytics.events"
					:icon="{
						component: 'ni ni-calendar-grid-58',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Tickets -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Tickets"
					:value="analytics.tickets"
					:icon="{
						component: 'ni ni-money-coins',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Reservations -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Reservations"
					name="Reservations"
					:value="analytics.reservations"
					:icon="{
						component: 'ni ni-support-16',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Bookings -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Bookings"
					name="Bookings"
					:value="analytics.bookings"
					:icon="{
						component: 'ni ni-books',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Bottles -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Bottles"
					name="Bottles"
					:value="analytics.bottleOrders"
					:icon="{
						component: 'ni ni-books',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Reviews -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Reviews"
					name="Reviews"
					:value="analytics.reviews"
					:icon="{
						component: 'ni ni-books',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Gift Cards -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Gift Cards"
					name="GiftCards"
					:value="analytics.giftCards"
					:icon="{
						component: 'ni ni-books',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Careers -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Careers"
					name="Careers"
					:value="analytics.careers"
					:icon="{
						component: 'ni ni-books',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

			<!-- Mailing Lists -->
			<div class="col-xl-4 col-sm-6 mb-xl-0 mb-4">
				<mini-statistics-card
					title="Mailing Lists"
					name="MailingLists"
					:value="analytics.mailingLists"
					:icon="{
						component: 'ni ni-books',
						background: iconBackground,
					}"
					direction-reverse
				/>
			</div>

		</div>

		<!-- <div class="row">

			<div class="col-lg-7 mb-lg-0 mb-4">
				<div class="card">
					<div class="card-body p-3">
						<div class="row">
							<div class="col-lg-6">
								<div class="d-flex flex-column h-100">
									<p class="mb-1 pt-2 text-bold">Built by developers</p>
									<h5 class="font-weight-bolder">Soft UI Dashboard</h5>
									<p class="mb-5">
										From colors, cards, typography to complex elements, you will
										find the full documentation.
									</p>
									<a class="text-body text-sm font-weight-bold mb-0 icon-move-right mt-auto"
										href="javascript:;">
										Read More
										<i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
									</a>
								</div>
							</div>
							<div class="col-lg-5 ms-auto text-center mt-5 mt-lg-0">
								<div class="bg-gradient-success border-radius-lg h-100">
									<img src="../assets/img/shapes/waves-white.svg"
										class="position-absolute h-100 w-50 top-0 d-lg-block d-none" alt="waves" />
									<div
										class="position-relative d-flex align-items-center justify-content-center h-100">
										<img class="w-100 position-relative z-index-2 pt-4"
											src="../assets/img/illustrations/rocket-white.png" alt="rocket" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="col-lg-5">
				<div class="card h-100 p-3">
					<div class="overflow-hidden position-relative border-radius-lg bg-cover h-100" style="background-image: url('https://demos.creative-tim.com/soft-ui-dashboard/assets/img/ivancik.jpg');">
						<span class="mask bg-gradient-dark"></span>
						<div class="card-body position-relative z-index-1 p-3 h-100">
							<div class="d-flex flex-column h-100">
								<h5 class="text-white font-weight-bolder mb-4 pt-2">
									Work with the rockets
								</h5>
								<p class="text-white mb-5">
									Wealth creation is an evolutionarily recent positive-sum game.
									It is all about who take the opportunity first.
								</p>
								<a class="text-white font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
									href="javascript:;">
									Read more
									<i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div> -->

	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MiniStatisticsCard from '@/components/MiniStatisticsCard'

export default {
	name: 'Dashboard',
	data() {
		return {
			iconBackground: 'bg-gradient-primary',
		}
	},
	computed: {
		...mapGetters('dashboard', [
			'analytics'
		])
	},
	components: {
		MiniStatisticsCard
	},

	beforeMount () {
		this.getDashboardAnalyticsAction()
	},

	methods: {
		...mapActions('dashboard', [
			'getDashboardAnalyticsAction'
		]),
	},
}
</script>
